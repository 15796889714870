<template>
  <v-main>
    <app-menu />
      <div class="text-center">
        <v-dialog v-model="isLoad" persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              SUBIENDO IMAGENES ...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
         <v-toolbar>    
          <v-tabs v-model="tab"
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
           >
            <v-tabs-slider color="yellow"></v-tabs-slider> 
            <v-tabs v-model="tab" background-color="primary"  grow dark >
             
            <v-tab>
              Paquetería 
            </v-tab>
            </v-tabs>  
          </v-tabs> 
        </v-toolbar> 
        <v-card min-height="100%" > 
         
          <Paqueteria v-if="tab==0" />

        </v-card>
  </v-main>
</template>

<script>
import axios from "axios";
import config from "../../json/config.json";
import AppMenu from '../../components/admin/MenuAdmin.vue'
import Paqueteria from "../../components/admin/TablaPaqueteria.vue";
export default {
  name: "HomeAdmin",
  data() {
    return {
      // imagen:{
      //   fechaInicio:'',
      //   fechaFinal:'',
      // },
      fechaInicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fechaFinal: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal:false,
      modalf:false,
      tab : "",
      loading: false,
      sliderImg2: [],
      indexSlider: 0,
      files: null,
      imagenes: [],
      sliderImg: [],
      alert: { show: false },
      msgerror: "",
      isLoad: true,
      validarFecha:false
    };
  },

  methods: {
    imgUpload: async function(fechaInicio,fechaFinal,validarFecha) {

      let valid = this.$refs.formImg.validate();
      if(valid){
        this.loading = true;
        let formData = new FormData();
        for (const i of Object.keys(this.files)) {
          formData.append("files", this.files[i]);
        }
       
        await axios
          .post(config.apiAmoresens + `/img/file-upload`,formData, {
            headers: { token: this.$cookie.get("token") }, 
            params: {fechaInicio : fechaInicio,fechaFinal:fechaFinal,validarFecha:validarFecha}
          })
          .then((res) => {
            this.imagenes = res.data;
            this.files = null;
          }).catch(err=>{
            console.log(err);
          })
      }
      // this.loading = false;
    },
    //Para imagenes(slider)
    // async ShowImg() {
    //   this.sliderImg = await axios
    //     .get(config.apiAmoresens + "/img/get_img", {
    //       headers: { token: this.$cookie.get("token") },
    //       data: { filer: {} },
    //     })
    //     .then((res) => {
    //       return res.data;
    //     })
    //     .catch((err) => {
    //       return err;
    //     });
    //   if (this.sliderImg.status == "success") {
    //     this.sliderImg = this.sliderImg.data[2].files; // Específicar el arreglo a mostrar de mongoDB
    //     console.log(this.sliderImg);
    //   }
    // },
    // changeSlider() {
    //   if (this.indexSlider == this.sliderImg.length - 1) {
    //     this.indexSlider = 1;
    //   } else {
    //     this.indexSlider++;
    //   }
    // },
  },
  watch: {
      loading (val) {
        if (!val) return

        setTimeout(() => (this.loading = false), 4000)
      },
  },
  components: {
    "app-menu": AppMenu,
      Paqueteria

    // "app-slider": AppSlider,
  },
  async mounted() {
    window.scrollTo(0, 0);
    //
    // this.readRegionname()
    // this.ShowImg();
  },
  computed: {},

  async created() {
    // let date = 
    this.isLoad = true;
    this.user = await axios.get(config.apiAmoresens + "/user/userByTokenAdmin", 
        {'headers': { 'token': this.$cookie.get('token') }})
        .then(res=>{return res.data;})
        .catch(err=>{return err;});     
        if (this.user.status == "success") {
          this.user = this.user.user; 
          this.isLogged = true;
        }else if(this.user.status == "unauthorized"){ 
          this.$router.push('/shop/admin'+config.matchAdmin+'/login');
          this.isLogged = false;
        } 
        
    this.isLoad = false;
  },
};
</script>

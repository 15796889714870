<template>
    <div>
        <v-container>
            <div class="text-center">
                <v-dialog v-model="isLoad" persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                    Cargando ...
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                    </v-card-text>
                </v-card>
                </v-dialog>
            </div>
            <v-card >
                <v-card-title>
                    <v-text-field v-model="search"  prepend-icon="mdi-package-variant" label="Buscar paquetería"></v-text-field>
                </v-card-title>
                <div class="container">
                    <v-btn
                    text
                    color="purple"
                    dark
                    class="mb-2"
                    @click="addPaqueteria=true"
                    ><v-icon color="orange" style="font-size:29px;">mdi-package-variant-closed</v-icon>
                    Agregar Paquetería
                    </v-btn>
                </div>
                <v-dialog max-width="400px" v-model="addPaqueteria">
                    <v-card class="justify-center">
                        <v-card dark  color="purple lighten-1" style="border-radius:0px 0px 180px 0px;">
                            <v-card-title class="text-h4">Agregar Paquetería</v-card-title>
                        </v-card>
                        <v-card-text  >
                            <v-container>
                                <v-alert v-if="mensaje != ''" dense outlined type="error">
                                    {{mensaje}}
                                </v-alert> 
                                        <br>
                                        <v-row style="justify-content: center;">
                                            <!-- <v-col  cols="12" sm="6"  md="12">
                                                <v-text-field
                                                id="numero_paqueteria_guia_prepagada"
                                                color="orange"
                                                type="Number"
                                                v-model="arrayAñadir.numero_paqueteria_guia_prepagada"
                                                label="Número de paquetería"
                                                :rules="[(v) => !! v || 'Número de paquetería es requerido']"
                                                ></v-text-field>
                                            </v-col> -->
                                            <v-col  cols="12" sm="6"  md="7">
                                                <v-text-field
                                                color="orange"
                                                v-model="arrayAñadir.empresa"
                                                label="Nombre de la Sucursal"
                                                :rules="[(v) => !! v || 'Nombre de la Sucursal es requerido']"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6"  md="5">
                                                <v-text-field
                                                color="orange"
                                                v-model="arrayAñadir.descripcion"
                                                label="Descripción"
                                                :rules="[(v) => !! v || 'Descripción es requerido']"
                                                ></v-text-field>

                                            </v-col>
                                            <v-col cols="12" sm="6"  md="6">
                                                <v-text-field
                                                type="Number"
                                                color="orange"
                                                v-model="arrayAñadir.costo"
                                                label="Costo $"
                                                :rules="[(v) => !! v || 'Costo es requerido']"
                                                ></v-text-field>

                                            </v-col>
                                            <v-col cols="12" sm="6"  md="6">
                                                <v-text-field
                                                type="Number"
                                                color="orange"
                                                v-model="arrayAñadir.peso"
                                                label="Peso Kg."
                                                :rules="[(v) => !! v || 'Peso Kg. es requerido']"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6"  md="6">
                                                <v-text-field
                                                type="Number"
                                                color="orange"
                                                v-model="arrayAñadir.intervalo1"
                                                label="Intervalo 1"
                                                :rules="[(v) => !! v || 'Intervalo 1 es requerido']"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6"  md="6">
                                                <v-text-field
                                                type="Number"
                                                color="orange"
                                                v-model="arrayAñadir.intervalo2"
                                                label="Intervalo 2"
                                                :rules="[(v) => !! v || 'Intervalo 2 es requerido']"
                                                ></v-text-field>
                                            </v-col>
                                            <!-- <v-col cols="12" sm="6"  md="6">
                                                <v-text-field
                                                color="orange"
                                                v-model="arrayAñadir.creado_por"
                                                label="Creado por:"
                                                :rules="[(v) => !! v || 'Creado por: es requerido']"
                                                ></v-text-field>
                                            </v-col> -->
                                            <v-col>
                                                <v-radio-group v-model="arrayAñadir.activo">
                                                    <v-radio color="orange" label="Activo" :value="arrayAñadir.activo = true"></v-radio>
                                                    <v-radio color="red" label="Inactivo" :value="arrayAñadir.activo = false"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                        </v-card-text>
                        <v-divider color="orange"></v-divider>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="orange"
                            text
                            @click="crud(arrayAñadir)"
                        >
                            Guardar
                        </v-btn>
                        <v-btn
                            color="error darken-1"
                            text
                            @click="addPaqueteria=false"
                        >
                            Cancelar
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-data-table 
                    no-data-text="No hay datos disponibles"
                    no-results-text="No hay coincidencias de tu busqueda"
                    :footer-props="{'items-per-page-options':[10,20,30,40]}"
                    :headers="headers" :items="paqueteria" :search="search">
                    <template v-slot:[`item.empresa`]="{ item }">
                        <center>
                            <div style="color:purple; font-size:18px;">
                                {{item.empresa}}
                            </div>
                        </center>
                     </template>
                     <template v-slot:[`item.costo`]="{ item }">
                         <center>
                            {{formatMXN(item.costo)}}
                         </center>
                     </template>
                    <template v-slot:[`top`]>
                        <v-toolbar
                            flat
                        >
                            <v-spacer></v-spacer>
                            <v-dialog 
                            v-model="dialog"
                            max-width="400px"
                            transition="dialog-top-transition"
                            >
                            <v-card>
                                <v-card dark  color="orange lighten-1" style="border-radius:0px 0px 180px 0px;">
                                    <v-card-title class="text-h4">Editar Paquetería</v-card-title>
                                </v-card>
                                <v-card-text  >
                                    <v-container>
                                        <br>
                                        <v-row style="justify-content: center;">
                                            
                                            <v-col  cols="12" sm="6"  md="5">
                                                <v-text-field
                                                color="purple"
                                                v-model="arrayEditar.empresa"
                                                label="Nombre de la Sucursal"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6"  md="7">
                                                <v-text-field
                                                color="purple"
                                                v-model="arrayEditar.descripcion"
                                                label="Descripción"
                                                ></v-text-field>

                                            </v-col>
                                            <v-col cols="12" sm="6"  md="6">
                                                <v-text-field
                                                color="purple"
                                                v-model="arrayEditar.costo"
                                                label="Costo $"
                                                ></v-text-field>

                                            </v-col>
                                            <v-col cols="12" sm="6"  md="6">
                                                <v-text-field
                                                color="purple"
                                                v-model="arrayEditar.peso"
                                                label="Peso Kg."
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6"  md="6">
                                                <v-text-field
                                                color="purple"
                                                v-model="arrayEditar.intervalo1"
                                                label="Intervalo 1"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6"  md="6">
                                                <v-text-field
                                                color="purple"
                                                v-model="arrayEditar.intervalo2"
                                                label="Intervalo 2"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-radio-group v-model="arrayEditar.activo">
                                                    <!-- {{arrayEditar.activo}} -->
                                                    <v-radio color="orange" label="Activo" :value="true"></v-radio>
                                                    <v-radio color="red" label="Inactivo" :value="false"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-divider color="orange"></v-divider>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                
                                <v-btn
                                    color="orange"
                                    text
                                    @click="EditarPaqueteria(arrayEditar)"
                                >
                                    Guardar
                                </v-btn>
                                <v-btn
                                    color="error darken-1"
                                    text
                                    @click="Cerrar"
                                >
                                    Cancelar
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                        <template v-slot:[`item.acciones`]="{ item }">
                            <center>
                                <v-icon style="font-size:29px;" color="orange" @click="editItem(item)">
                                    mdi-package-variant
                                </v-icon>
                            </center>
                        </template>
                    <template v-slot:[`item.activo`]="{ item }">
                        <center></center>
                        <v-radio-group mandatory style="margin-left: 25%;">
                            <v-radio  color="orange" v-if="item.activo==true" label="SI"></v-radio>
                            <v-radio  color="purple" v-if="item.activo==false" label="NO"></v-radio>
                        </v-radio-group>
                
                    </template>
                    <template v-slot:[`item.numero_paqueteria_guia_prepagada`]="{ item }">
                        <center>
                            {{item.numero_paqueteria_guia_prepagada}}
                        </center>
                    </template>
                    <template v-slot:[`item.peso`]="{ item }">
                        <center>
                            {{item.peso}}
                        </center>
                    </template>
                    <template v-slot:[`item.intervalo1`]="{ item }">
                        <center>
                            {{item.intervalo1}}
                        </center>
                    </template>
                    <template v-slot:[`item.intervalo2`]="{ item }">
                        <center>
                            {{item.intervalo2}}
                        </center>
                    </template>
                    <template v-slot:[`item.descripcion`]="{ item }">
                        <center>
                            {{item.descripcion}}
                        </center>
                    </template>
                </v-data-table>

            </v-card>
        </v-container>
       
    </div>
</template>
<style>
    .v-card .v-data-table-header th[role=columnheader] {
        font-size: 13px !important;
        text-align: center !important;
    }
</style>
<script>
import axios from 'axios';
import config from '../../json/config.json'

export default {
    data:() =>({
        dialog:false,
        search:'',
        headers:[
            {text:'N°',value:'numero_paqueteria_guia_prepagada'},
            {text:'Empresa', value:'empresa'},
            {text:'Descripción',value:'descripcion'},
            {text:'Costo $',value:'costo'},
            {text:'Peso Kg.',value:'peso'},
            {text:'Intervalo 1',value:'intervalo1'},
            {text:'Intervalo 2',value:'intervalo2'},
            {text:'Activo',value:'activo'},
            {text:'Acciones',value:'acciones'}
            
        ],
        paqueteria:[],
        isLoad:false,
        eliminard:false,
        arrayEditar:[],
        arrayAñadir:{},
        addPaqueteria:false,
        mensaje:'',
        
    }),
    methods:{
        async MostrarPaqueteria(){ 
            await axios.get(config.apiAmoresens + "/paqueteriaguiaprepagada/obtener_paqueteria_admin",
            {'headers': { 'token': this.$cookie.get('token') }}
            )
            .then(res=>{
                this.paqueteria = res.data.data;
            })
            .catch(err=>{return err});
        },
        async añadirPaqueteria(){
 
            this.arrayAñadir.creado_por = this.user.ad_user_id;
            await axios.post(config.apiAmoresens + '/paqueteriaguiaprepagada/agregar', this.arrayAñadir,
            {'headers': { 'token': this.$cookie.get('token') }})
            .then((res) => {
                return  res.data;
            }).catch(err=>{
                return err
            })
            this.mensaje='';
            this.addPaqueteria=false;
            this.arrayAñadir.numero_paqueteria_guia_prepagada = '';
            this.arrayAñadir.empresa = '';
            this.arrayAñadir.costo = '';
            this.arrayAñadir.intervalo1 = '';
            this.arrayAñadir.intervalo2 = '';
            this.arrayAñadir.descripcion = '';
            this.arrayAñadir.peso = '';
            this.MostrarPaqueteria();
            this.isLoad = false;

        },
        async EditarPaqueteria(){
            this.isLoad = true;
            await axios.put(config.apiAmoresens + '/paqueteriaguiaprepagada/editar_paqueteria',
            this.arrayEditar,{'headers': { 'token': this.$cookie.get('token') }})
            .then(res=>{
                this.arrayEditar = res.data
            })
            .catch(err=>{
                return err
            });
            this.dialog = false;
            this.MostrarPaqueteria();
            this.isLoad = false;
        },
        editItem (item) {
            this.editedIndex = this.paqueteria.indexOf(item)
            this.arrayEditar = Object.assign({}, item)
            this.dialog = true
        },
        Cerrar () {
            this.dialog = false
            this.$nextTick(() => {
            this.editeditem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
            })
        },
        formatMXN(value) {
            var formatter = new Intl.NumberFormat('en-ES', {style: 'currency', currency: 'USD',});
            return `${formatter.format(value)} MXN`;
        }, 
        crud(arrayAñadir){
            this.arrayAñadir = arrayAñadir;
            this.añadirPaqueteria(this.arrayAñadir);

        },
        async validaLogin(){
          this.user = await axios.get(config.apiAmoresens + "/user/userByTokenAdmin", 
          {
            'headers': { 'token': this.$cookie.get('token') }
          }).then(res=>{return res.data;})
          .catch(err=>{return err;});
          if (this.user.status == "success") {
            this.user = this.user.user;
            this.isLogged = true; 
          }else if(this.user.status == "unauthorized"){ 
            this.isLogged = false;
          } 
        }
    
    },
    computed: {
      
    },
    created(){
        this.MostrarPaqueteria();
        this.validaLogin();
    }
}
</script>   